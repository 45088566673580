import { categories } from "../../../../data/filtre"
import FiltreItem from "../FiltreItem/FiltreItem"


export const FiltreItems = ({setMenuIsOpen}) => {
    
    const generateFiltre = () => {
        const listeFiltre = []
        categories.forEach(categorie => {
            listeFiltre.push(<FiltreItem setMenuIsOpen={setMenuIsOpen} nomClass={"FiltreItem FiltreItem_principal"} value={categorie.nom} key={categorie.nom}/>)
            categorie.sousCategorie.forEach(element => {
            listeFiltre.push(<FiltreItem setMenuIsOpen={setMenuIsOpen} nomClass={"FiltreItem"} value={element} key={element}/>)
            })
        })
        return listeFiltre
    }


    return (
        <div className="FiltreItems">
            {generateFiltre()}
        </div>
    )
}
export default FiltreItems