import { useSelector } from "react-redux"

export const CompteurPanier = () => {
    const listeItem = useSelector((state) => state.panier.panier)
    let compteur = listeItem.length
    

    if (compteur != 0){
        return (
            <p className="CompteurPanier">
                {compteur}
            </p>
        )
    }
    return (
        <>
        </>
    )
}
export default CompteurPanier