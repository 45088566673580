import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { useEffect, useState } from "react"
import QuantityButton from "../../button/quantityButton/quantityButton"
import UploadButton from "../../button/uploadButton/UploadButton"
import DeleteButton from "../../button/deleteButton/DeleteButton"

export const CartePanier = ({ produitPanier, id }) => {
    const [imageSrc, setImageSrc] = useState(null)
    const [loading, setLoading] = useState(false)
    const [quantity, setQuantity] = useState(produitPanier.quantity)
    const storage = getStorage();
    
    useEffect(() => {
        const fetchData = async () => {
            const fetchData = async () => {
                if (produitPanier.optionColor === "Black" || produitPanier.optionColor === "Silver" || produitPanier.optionColor === "Slate grey" || produitPanier.optionColor === "Craft") {
                    setImageSrc(await getDownloadURL(ref(storage, "products-images/" + produitPanier.idProduct + "/mainImage")))

                } else {
                    setImageSrc(await getDownloadURL(ref(storage, "products-images/" + produitPanier.idProduct + "/" + produitPanier.optionColor)))
                }
                setLoading(true)
            }
            fetchData()
            setLoading(true)
        }
        fetchData()
    }, [loading]
    )

    if (loading) {
        return (
            <div className="CartePanier">
                <img src={imageSrc} className="CartePanier_image"></img>
                <div className="CartePanier_header">
                    <div className="CartePanier_information">
                        <div className="CartePanier_information_header">
                            <h2>{produitPanier.product.nom} </h2>
                            <div className="CartePanier_information_color">
                                <span className="CartePanier_information_color_span" style={{ backgroundColor: produitPanier.optionColor }}></span>
                                <p>{produitPanier.optionColor}</p>
                            </div>
                        </div>
                        <p className="CartePanier_information_duree">12-14 days</p>
                    </div>
                    <div className="CartePanier_quantity">
                    </div>
                </div>
                <div className="CartePanier_buttons">
                    <DeleteButton produitPanier={produitPanier}/>
                </div>
            </div>
        )
    } else {
        return (
            <div className="CartePanier">
                Chargement...
            </div>
        )
    }

}
export default CartePanier