import { useLocation } from "react-router";
import PanierArianeItem from "./panierArianeItem/PanierArianeItem"

export const PanierAriane = () => {
    const location = useLocation();

    if (location.pathname === "/order") {
        return (
            <div className="PanierAriane">
                <div className="PanierAriane_cote">
                    <PanierArianeItem index={"1"} text={"Quote"} actif={true} mobile={"mobile_false"} />
                    <PanierArianeItem index={"2"} text={"Identification & design"} actif={true} mobile={"mobile_true"}/>
                </div>
                <div className="PanierAriane_cote"> 
                    <PanierArianeItem index={"3"} text={"Shipping"} actif={false} mobile={"mobile_false"}/>
                    <PanierArianeItem index={"4"} text={"Summary"} actif={false} mobile={"mobile_false"}/>
                </div>
            </div>
        )
    } else if (location.pathname === "/shipping"){
        return (
            <div className="PanierAriane">
                <div className="PanierAriane_cote">
                    <PanierArianeItem index={"1"} text={"Quote"} actif={true} mobile={"mobile_false"}/>
                    <PanierArianeItem index={"2"} text={"Identification & design"} actif={true} mobile={"mobile_false"}/>
                    <PanierArianeItem index={"3"} text={"Shipping"} actif={true} mobile={"mobile_true"}/>
                </div>
                <div className="PanierAriane_cote">
                    <PanierArianeItem index={"4"} text={"Summary"} actif={false} mobile={"mobile_false"}/>
                </div>
            </div>
        )
    }
    else if (location.pathname === "/summary"){
        return (
            <div className="PanierAriane">
                <div className="PanierAriane_cote">
                    <PanierArianeItem index={"1"} text={"Quote"} actif={true} mobile={"mobile_false"}/>
                    <PanierArianeItem index={"2"} text={"Identification & design"} actif={true} mobile={"mobile_false"} />
                    <PanierArianeItem index={"3"} text={"Shipping"} actif={true} mobile={"mobile_false"}/>
                    <PanierArianeItem index={"4"} text={"Summary"} actif={true} mobile={"mobile_true"}/>
                </div>
                <div className="PanierAriane_cote">
                </div>
            </div>
        )
    }
    else{
        return (
            <div className="PanierAriane">
                <div className="PanierAriane_cote">
                    <PanierArianeItem index={"1"} text={"Quote"} actif={true} mobile={"mobile_true"}/>
                </div>
                <div className="PanierAriane_cote">
                    <PanierArianeItem index={"2"} text={"Identification & design"} actif={false}  mobile={"mobile_false"}/>
                    <PanierArianeItem index={"3"} text={"Shipping"} actif={false}  mobile={"mobile_false"}/>
                    <PanierArianeItem index={"4"} text={"Summary"} actif={false} mobile={"mobile_false"}/>
                </div>
            </div>
        )
    }
}
export default PanierAriane