export const QuantityButton = ({ quantity, setQuantity, quantityMin }) => {


    const isValid = () => {
        if (quantity >= quantityMin) {
            return true
        }
        return false
    }
    const quantityValid = isValid() ? "QuantityButton QuantityButton_valid" : "QuantityButton"
    const handleClickMoins = (e) => {
        e.preventDefault()
        if(Number(quantity) > quantityMin){
            setQuantity(Number(quantity) - 1)
        }
    }
    const handleClickPlus = (e) => {
        e.preventDefault()
        setQuantity(Number(quantity) + 1)
    }

    const changeInput = (e) => {
            setQuantity(Number(e.target.value))

    }

    return (
        <div className={quantityValid}>
            <div className="QuantityButton_text">
                <h3 >Quantity: </h3>
                <p>(min. quantity: {quantityMin})</p>
            </div>
            <div className='QuantityButton_compteur'>
                <button className="QuantityButton_compteur_button" id="QuantityButton_compteur_button_left" onClick={handleClickMoins}>-</button>
                <input
                    className='QuantityButton_compteur_input'
                    placeholder={quantityMin}
                    type="number"
                    value={quantity}
                    min={quantityMin}
                    onChange={changeInput}
                >
                </input>
                <button className="QuantityButton_compteur_button" id="QuantityButton_compteur_button_right" onClick={handleClickPlus}>+</button>
            </div>
        </div>
    )
}
export default QuantityButton