export const PanierArianeItem = ({index, text, actif, mobile}) => {
    
    const isActif = actif ? "PanierArianeItem PanierArianeItem_actif " : "PanierArianeItem "

    return (
        <div className={isActif + mobile}>
            <p className="PanierArianeItem_index">{index}</p>
            <p className="PanierArianeItem_text">{text}</p>
        </div>
    )
}
export default PanierArianeItem